// Accordion
//

.ce_accordion
  margin-bottom: $spacer

  .toggler
    background-color: $velo-blue
    color: $velo-white
    font-size: $h4-font-size
    padding: $spacer / 2 $spacer
    cursor: pointer

  .accordion
    border-bottom: 1px solid $velo-blue
    border-right: 1px solid $velo-blue
    border-left: 1px solid $velo-blue
    margin: 0 2 * $spacer

    > *
      padding: $spacer
