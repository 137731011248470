//
// Contact Form
//

.ce_form
  margin-bottom: 3rem

.widget
  @extend .form-group

  input, textarea
    @extend .form-control

  ::placeholder
    text-transform: uppercase

.widget-submit
  button
    @extend .btn
    @extend .btn-primary
    @extend .btn-block

#contact-form
  label
    display: none
