//
// Downloads
//

.ce_downloads

  .download-item
    border-top: 1px solid $gray-400
    &:last-child
      border-bottom: 1px solid $gray-400

    padding-top: $grid-gutter-width / 2
    padding-bottom: $grid-gutter-width / 2
