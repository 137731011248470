//
// Categories
//

.category-gallery
  display: grid
  grid-template-columns: 1fr
  @include media-breakpoint-up(md)
    grid-template-columns: 1fr 1fr
  @include media-breakpoint-up(lg)
    grid-template-columns: 1fr 1fr 1fr
  grid-auto-rows: 1fr
  grid-column-gap: 1px
  grid-row-gap: 1px

  margin-bottom: 2rem

  a
    color: $velo-white

  .category-item
    display: grid
    grid-template-rows: 1.1fr 1fr
    justify-content: stretch

    background-color: $velo-blue
    border: none

    padding: 2rem
    margin-left: 0
    margin-right: 1px
    &.last
      margin-right: 0

    text-align: center
    font-size: $h3-font-size
    line-height: $h3-font-size * 2

    opacity: 1
    transition: opacity 250ms ease-out

    &:hover
      opacity: .6

    .image
      padding-bottom: .5rem
      border-bottom: 1px solid $velo-white
      overflow: hidden
      figure
        margin: 0
        padding: 0
    &:hover img
      transform: scale(1.1)

    .caption
      align-self: center
      font-size: $h3-font-size
      line-height: $h3-font-size * 1.5
      text-transform: uppercase
