//
// Footer
//

#footer
  margin-top: 6rem
  border-top: 1px solid $gray-400

  font-size: $font-size-base * .75
  color: $gray-600

  .socials
    text-align: center
    padding: 3rem 0

    a
      font-size: $font-size-base * 1.5
      width: $font-size-base * 4
      line-height: $font-size-base * 4

      border-radius: 100%
      border-color: transparent

      &:not(:hover)
        background-color: $gray-400
