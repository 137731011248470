//
// Navbar
//

#header
  position: sticky
  z-index: $zindex-fixed
  top: 0
  left: 0
  right: 0
  width: 100%
  max-width: 100%

  background-color: $body-bg
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)

  font-family: $font-family-serif
  font-weight: $font-weight-bold
  font-size: $navbar-font-size

  img
    height: $nav-link-height
    width: auto
    @include media-breakpoint-down('md')
      height: $nav-link-height / 1.25
    @include media-breakpoint-down('sm')
      height: $nav-link-height / 1.5

  .inside
    display: flex
    flex-wrap: wrap
    justify-content: flex-end
    align-items: stretch
    align-content: stretch

    > *
      flex-grow: 0
      flex-shrink: 0

  #logo,.socials
    background-color: $velo-blue

  #logo
    flex-grow: 1

  .socials
    flex-grow: 1
    text-align: right

  .socials
    white-space: nowrap

    a
      width: $nav-link-height
      line-height: $nav-link-height
      @include media-breakpoint-down('md')
        width: $nav-link-height / 1.25
        line-height: $nav-link-height / 1.25
      @include media-breakpoint-down('sm')
        width: $nav-link-height / 1.5
        line-height: $nav-link-height / 1.5

.mod_onepage_navigation
  @extend .navbar
  @extend .navbar-expand-lg
  @extend .navbar-light
  background-color: $body-bg

  .navbar-toggler
    border: none
    padding: 0
    width: $nav-link-height
    line-height: $nav-link-height
    @include media-breakpoint-down('md')
      width: $nav-link-height / 1.25
      line-height: $nav-link-height / 1.25
    @include media-breakpoint-down('sm')
      width: $nav-link-height / 1.5
      line-height: $nav-link-height / 1.5

.mod_onepage_navigation
  &.navigation
    @include media-breakpoint-down('md')
      min-width: 95%

  ul
    @extend .navbar-nav
    padding: 0 $navbar-nav-link-padding-x

    li
      @extend .nav-item
      margin: 0 $navbar-nav-link-padding-x
      line-height: $nav-link-height
      @include media-breakpoint-down('md')
        line-height: 2.0
      text-transform: uppercase

      &.active a:after,
      &:hover a:after
        height: 10px
        display: block

    a
      @extend .nav-link
      position: relative
      padding: 0 !important

      &:after
        background: $navbar-light-color
        content: ''
        position: absolute
        left: 0
        bottom: 0
        width: 100%
        height: 0
        transition: height 0.3s ease
