//
// Headings
//

h1
  text-align: center

  margin-top: $headings-margin-bottom * 2
  margin-bottom: $headings-margin-bottom * 2

  font-family: $font-family-serif

h2,
h3
  text-transform: uppercase

h2.ce_headline
  color: $velo-white
  background-color: $velo-blue

  padding: 1.25rem 0

  text-align: center
  font-family: $font-family-serif
  font-weight: $font-weight-normal

h3
  margin-bottom: $headings-margin-bottom / 2

h3, h4, h5, h6
  &.ce_headline
    @extend .container
