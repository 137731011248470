//
// Images/Figures
//

img
  max-width: 100%
  height: auto

main,
#main
  a img
    transition: opacity 250ms ease-out, transform 250ms ease-out

    &:hover
      opacity: .6

figure
  text-align: center
