@import "variables"

@import "~bootstrap/scss/bootstrap-reboot"
@import "~bootstrap/scss/type"
@import "~bootstrap/scss/buttons"
@import "~bootstrap/scss/grid"
@import "~bootstrap/scss/close"
@import "~bootstrap/scss/utilities"
@import "~bootstrap/scss/transitions"
@import "~bootstrap/scss/nav"
@import "~bootstrap/scss/navbar"
@import "~bootstrap/scss/forms"
@import "~bootstrap/scss/tables"
@import "~bootstrap/scss/card"
@import "~bootstrap/scss/modal"

@import '~@fortawesome/fontawesome-free/scss/fontawesome'
@import '~@fortawesome/fontawesome-free/scss/solid'
@import '~@fortawesome/fontawesome-free/scss/regular'
@import '~@fortawesome/fontawesome-free/scss/brands'


//
// Extend Font Awesome
//

.#{$fa-css-prefix}-rotate-45
  @include fa-icon-rotate(45deg, 1)

.#{$fa-css-prefix}-rotate-235
  @include fa-icon-rotate(235deg, 1)

.#{$fa-css-prefix}-rotate-315
  @include fa-icon-rotate(315deg, 1)


//
// Contao utilities
//

.invisible
  border: 0
  clip: rect(0 0 0 0)
  height: 1px
  margin: -1px
  overflow: hidden
  padding: 0
  position: absolute
  width: 1px


//
// Basics
//

html
  font-size: 16px

iframe
  border: none
  max-width: 100%

#wrapper
  padding-bottom: 6rem

a
  cursor: pointer


@import "type"
@import "images"
@import "navbar"
@import "social"
@import "icon_link"
@import "content"
@import "carousel"
@import "forms"
@import "newsletter"
@import "gallery"
@import "downloads"
@import "exhibitors"
@import "events"
@import "categories"
@import "footer"
@import "cookie-consent"
@import "accordion"
