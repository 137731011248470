//
// Events
//

.event-actions
  @supports (position: sticky)
    @include media-breakpoint-up(md)
      position: sticky
      top: 10rem
      z-index: $zindex-sticky

  padding-top: 1rem
  padding-bottom: 1rem
  background-color: $body-bg

  > *
    margin: 0 auto 2rem auto
  > *:last-child
    margin-bottom: 1rem

.event-gallery
  display: grid
  grid-template-columns: 1fr
  @include media-breakpoint-up(sm)
    grid-template-columns: 1fr 1fr
  @include media-breakpoint-up(lg)
    grid-template-columns: 1fr 1fr 1fr
  @include media-breakpoint-up(xl)
    grid-template-columns: 1fr 1fr 1fr 1fr
  grid-auto-rows: 1fr
  grid-column-gap: 1rem
  grid-row-gap: 1rem

  margin-bottom: 2rem

  > .event-item
    background-color: $velo-blue
    color: $velo-white
    position: relative

    a
      display: block
      height: 100%
      width: 100%

    .caption
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      background-color: $velo-blue
      color: $velo-white
      overflow: hidden
      padding: .5rem

      > .event-date
        display: none

    @media(hover: hover)
      .caption
        height: 4.2rem
        transition: height 350ms ease-in-out

        > .event-date
          display: block
      &:hover .caption
        height: 100%

    figure
      background-color: $velo-white
      margin: 0

.mod_event_list
  h3
    @include media-breakpoint-up(md)
      position: sticky
      top: 20rem
      background-color: $velo-white
      z-index: $zindex-sticky
      margin-bottom: 0
      padding-bottom: $headings-margin-bottom / 2

  .event-item + h3
    margin-top: 3rem

  .event-item
    @extend .card
    margin-bottom: 1rem

    .inside
      @extend .card-body
