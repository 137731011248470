//
// Icon Link
//

.ce_icon_link
  @extend .container
  width: 30%
  @include media-breakpoint-down(md)
    width: 50%
  @include media-breakpoint-down(sm)
    width: 75%
  @include media-breakpoint-down(xs)
    width: auto
    margin-left: $grid-gutter-width / 2
    margin-right: $grid-gutter-width / 2
  background-color: $velo-blue
  color: $velo-white
  margin-top: $grid-gutter-width / 2
  margin-bottom: $grid-gutter-width / 2
  font-size: $h3-font-size
  line-height: $h3-font-size * 2
  padding: 2rem
  opacity: 1
  transition: opacity 250ms ease-out

  &:hover
    opacity: .6

  a
    color: $velo-white

  i
    font-size: $h3-font-size * 2.5
    line-height: $h3-font-size * 3.5
    display: block
    width: 100%
    text-align: center
    transition: font-size 250ms ease-out

  &:hover i
    font-size: $h3-font-size * 3

  span
    font-size: $h3-font-size
    line-height: $h3-font-size * 1.5
    display: block
    width: 100%
    text-align: center
    text-transform: uppercase
    margin: 2rem 0 0 0
    padding: 2rem 0 1rem 0
    border-top: 1px solid $velo-white


.nav_icon_links
  @extend .card-deck
  padding: 0 $grid-gutter-width / 2

  @include media-breakpoint-down(sm)
    padding: 0

  @include media-breakpoint-up(sm)
    .card
      flex-basis: 40%
      margin-bottom: $grid-gutter-width

  @include media-breakpoint-up(lg)
    .card
      flex-basis: 0%

  &.cards-per-row-2
    .card
      flex-basis: 40%

  a
    color: $velo-white

  .card
    background-color: $velo-blue
    border: none

    padding: 2rem
    margin-left: 0
    margin-right: 1px
    &.last
      margin-right: 0

    text-align: center
    font-size: $h3-font-size
    line-height: $h3-font-size * 2

    opacity: 1
    transition: opacity 250ms ease-out

    &:hover
      opacity: .6

    i
      font-size: $h3-font-size * 2.5
      line-height: $h3-font-size * 3.5
      transition: font-size 250ms ease-out

    &:hover i
      font-size: $h3-font-size * 3

    span
      font-size: $h3-font-size
      line-height: $h3-font-size * 1.5
      text-transform: uppercase
      margin: 2rem 0 0 0
      padding: 2rem 0 1rem 0
      border-top: 1px solid $velo-white
