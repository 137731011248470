//
// Newsletter
//

.mod_newsletter_signup
  margin-bottom: 3rem

  label
    display: none

  .checkbox-inline label
    display: inline-block
    padding-left: 1rem

  input[type="text"],
  input[type="email"]
    @extend .form-control

  button
    @extend .btn
    @extend .btn-block

  *[type="submit"]
    @extend .btn-primary

    // override newsletter2go styling
    padding: $btn-padding-y $btn-padding-x !important
    color: $white !important
    background-color: $primary !important
    border-color: $primary !important
    @if $enable-rounded
      border-radius: $btn-border-radius !important
    @else
      border-radius: 0 !important
