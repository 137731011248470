//
// Gallery
//

.ce_gallery
  ul
    list-style: none
    padding: 0
    margin-bottom: 0

    @include make-row()

    &.cols_1 li
      padding-bottom: $grid-gutter-width

      @include media-breakpoint-up('md')
          $num: 3 / $grid-columns
          padding-right: if($num == 0, 0, percentage($num))

    &.cols_3 li
      @include media-breakpoint-up(md)
        @include make-col(4)

    &.cols_4 li
      @include media-breakpoint-up(md)
        @include make-col(4)
      @include media-breakpoint-up(lg)
        @include make-col(3)

  li
    padding: 0
    margin: 0
    padding-bottom: $grid-gutter-width
    @include make-col-ready()
    @include make-col(12)
