//
// Content
//

.mod_article
  padding-top: 8rem

  @include media-breakpoint-down('md')
    padding-top: 4rem

  @include media-breakpoint-down('sm')
    padding-top: 2rem

  > .ce_headline
    margin-top: 0

  .mod_icon_navigation,
  .ce_text,
  .ce_hyperlink,
  .ce_form,
  .ce_downloads,
  .ce_gallery,
  .ce_iframe,
  .ce_accordion,
  .mod_exhibitor_list,
  .mod_event_list,
  .mod_event_gallery,
  .mod_category_gallery,
  .mod_category_detail,
  .mod_newsletter_signup
    @extend .container

  .row > .mod_icon_navigation,
  .row > .ce_text,
  .row > .ce_hyperlink,
  .row > .ce_form,
  .row > .ce_downloads,
  .row > .ce_gallery
    padding: 0 $grid-gutter-width / 2
    margin: 0


//
// Button Links
//

.hyperlink_btn
  @extend .btn
  @extend .btn-primary
  @extend .btn-block
