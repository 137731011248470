//
// Cookie Consent
//

#cookie-consent-overlay
  position: fixed
  z-index: 10000

  bottom: 0
  left: 0
  width: 100%

  padding: 1.25rem 2.5rem 0 2.5rem // 20 40

  background-color: rgba(10, 10, 10, .8)
  color: #efefef

  font-size: $font-size-base

  .btn
    padding: $btn-padding-y-sm $btn-padding-x-sm
    margin-bottom: 1.25rem // 20
