//
// Slider / Carousel
//

@import "~tiny-slider/dist/tiny-slider.css"

.logo-slider
  display: flex
  flex-wrap: nowrap
  @include media-breakpoint-down(sm)
    flex-wrap: wrap

  > *
    align-self: center

  .slider-container
    display: flex
    align-items: center
    justify-content: space-between

  .tns-item
    text-align: center

  .slider-control
    flex-grow: 0
    flex-shrink: 0
    cursor: pointer
    @include media-breakpoint-down(sm)
      order: 2
      width: 50%

    &[data-slide="next"]
      text-align: right

    &,
    &:focus,
    &:visited,
    &:active
      color: $gray-400

    &:hover
      color: $gray-600
