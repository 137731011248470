@import "~bootstrap/scss/functions"

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Paths

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts'


// Color system

$velo-white: #ffffff
$velo-blue: #2299ff
$velo-green: #33cc33

$brand-instagram: #d6016b
$brand-facebook: #3365A4
$brand-twitter: #55acee
$brand-youtube: #fd0001

$white:    #fff
$gray-100: #f8f9fa
$gray-200: #e9ecef
$gray-300: #dee2e6
$gray-400: #ced4da
$gray-500: #adb5bd
$gray-600: #6c757d
$gray-700: #495057
$gray-800: #343a40
$gray-900: #212529
$black:    #000

$blue:    $velo-blue
$indigo:  #6610f2
$purple:  #6f42c1
$pink:    #e83e8c
$red:     #dc3545
$orange:  #fd7e14
$yellow:  #ffc107
$green:   $velo-green
$teal:    #20c997
$cyan:    #17a2b8

$primary: $velo-green


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: false


// Links
//
// Style anchor elements.

$link-hover-decoration: none


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: news-gothic-std-1, news-gothic-std-2, "News Gothic Bold", Demi, "News Cycle", Verdana, Arial, sans-serif
$font-family-serif: ff-meta-serif-web-pro-1, ff-meta-serif-web-pro-2, "Meta Serif", "PT Serif", serif

$font-size-base: 1.25rem

$navbar-font-size: $font-size-base * 1.2

$h1-font-size:                $font-size-base * 2.6 // 3.25rem
$h2-font-size:                $font-size-base * 2 // 2.50rem
$h3-font-size:                $font-size-base * 1.4 // 2rem

$headings-margin-bottom: 3rem
$headings-font-family: $font-family-sans-serif
$headings-font-weight: 700
$headings-color: $velo-green


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1240px)


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (sm: 560px, md: 740px, lg: 980px, xl: 1200px)


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 1rem


// Forms

$input-bg:                              $gray-100
$input-disabled-bg:                     $gray-400

$input-border-color:                    $gray-300

$input-focus-border-color:              $input-border-color
$input-focus-box-shadow:                none

$input-placeholder-color:               $gray-500


// Navbar

$navbar-padding-y:                  0
$navbar-padding-x:                  0

$navbar-nav-link-padding-x:         1rem

$nav-link-padding-y:                1.6rem

//$nav-link-height:                   80px
//$navbar-brand-height:               80px
$navbar-brand-padding-y:            0

$navbar-light-color:                $primary
$navbar-light-hover-color:          $navbar-light-color
$navbar-light-active-color:         $navbar-light-color
//$navbar-light-disabled-color:       rgba($black, .3)
//$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
//$navbar-light-toggler-border-color: rgba($black, .1)

//$navbar-light-brand-color:                $navbar-light-active-color
//$navbar-light-brand-hover-color:          $navbar-light-active-color
//$navbar-dark-brand-color:                 $navbar-dark-active-color
//$navbar-dark-brand-hover-color:           $navbar-dark-active-color


// Add bootstrap variables
@import "~bootstrap/scss/variables"
