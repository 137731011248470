//
// Social Media
//

.socials
  a
    background-color: $velo-blue
    color: $velo-white
    text-align: center
    width: 2.5em
    line-height: 2.5em

.icon-facebook
  @extend .fab
  @extend %fa-icon
  transition: background-color 150ms ease-out
  &:before
    content: fa-content($fa-var-facebook-f)
  &:hover
    background-color: $brand-facebook

.icon-instagram
  @extend .fab
  @extend %fa-icon
  transition: background-color 150ms ease-out
  &:before
    content: fa-content($fa-var-instagram)
  &:hover
    background-color: $brand-instagram

.icon-twitter
  @extend .fab
  @extend %fa-icon
  transition: background-color 150ms ease-out
  &:before
    content: fa-content($fa-var-twitter)
  &:hover
    background-color: $brand-twitter

.icon-youtube
  @extend .fab
  @extend %fa-icon
  transition: background-color 150ms ease-out
  &:before
    content: fa-content($fa-var-youtube)
  &:hover
    background-color: $brand-youtube
