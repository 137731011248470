//
// Exhibitors
//

.exhibitor-actions
  @supports (position: sticky)
    @include media-breakpoint-up(md)
      position: sticky
      top: 10rem
      z-index: $zindex-sticky

  padding-top: 1rem
  padding-bottom: 1rem
  background-color: $body-bg

  > *
    margin: 0 auto 2rem auto
    @include media-breakpoint-up(md)
      width: 75%
  > *:last-child
    margin-bottom: 0


//
// List
//
.exhibitor-list-heading
  font-weight: $font-weight-bold
  padding-bottom: 1rem
  border-bottom: 1px solid $gray-300
  margin-bottom: 1rem

.mod_exhibitor_list
  .exhibitor-item
    padding-bottom: 1rem
    border-bottom: 1px solid $gray-300
    margin-bottom: 1rem

    h5
      margin-bottom: 1rem
      color: $link-color


//
// Filter
//

.exhibitor-pagination
  @include list-unstyled()

  display: grid
  grid-template-columns: repeat(auto-fit, 3rem)
  grid-column-gap: 0
  grid-row-gap: .5rem

  li
    text-align: center
    padding: 0 1rem

    border-right: 3px solid $gray-800
    &:last-child
      border-right: 3px solid transparent

  a
    color: $velo-blue
    font-size: $h3-font-size
    line-height: $h3-font-size
